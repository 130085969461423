<template>
	<div>
		<v-layout class="py-2">
			<div class="listing-heading">Porduct</div>
			<v-spacer></v-spacer>
			<v-btn color="grey lighten-3" tile depressed class="mx-3" @click="goBack()">
				<v-icon small left>mdi-arrow-left</v-icon>
				Back
			</v-btn>
		</v-layout>
		<div>
			<ModuleSettings type="product" title="product"></ModuleSettings>
		</div>
	</div>
</template>
<script>
import ModuleSettings from "@/view/components/ModuleSettings";
export default {
	name: "Porduct",
	data() {
		return {
			remark: null,
			pageLoading: null,
		};
	},
	components: {
		ModuleSettings,
	},
};
</script>
