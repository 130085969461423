<template>
	<v-layout class="d-flex setting-layout">
		<div class="setting-sidebar">
			<v-tabs
				vertical
				v-model="settingTab"
				background-color="red"
				class="w-100"
				slider-color="blue darken-4"
				active-class="blue lighten-5 blue--text text--darken-4"
			>
				<!-- <v-tab href="#personal_setting">Personal Setting</v-tab> -->
				<v-tab href="#company-details">Company Details</v-tab>
				<v-tab href="#role-permission">Role & Permission</v-tab>
				<v-tab href="#branding">Branding</v-tab>
				<v-tab href="#customer">Customer</v-tab>
				<v-tab href="#supplier">Supplier</v-tab>
				<v-tab href="#product">Product</v-tab>
				<v-tab href="#service">Service</v-tab>
				<v-tab href="#purchase-order">Purchase Order</v-tab>
				<v-tab href="#quotation">Quotation</v-tab>
				<v-tab href="#project">Project</v-tab>
				<v-tab href="#expense">Expense</v-tab>
				<v-tab href="#email-template">Email Template</v-tab>
				<v-tab href="#category">Category</v-tab>
				<v-tab href="#country-list">Country List</v-tab>
				<v-tab href="#terms-conditions">Terms and Conditions</v-tab>
			</v-tabs>
		</div>
		<div class="setting-content">
			<v-tabs-items v-model="settingTab">
				<!-- <v-tab-item value="personal_setting">
                   <PersonalSettings></PersonalSettings>
                </v-tab-item> -->
				<v-tab-item value="company-details">
					<CompanySetting></CompanySetting>
				</v-tab-item>
				<v-tab-item value="role-permission">
					<RolePermission></RolePermission>
				</v-tab-item>
				<v-tab-item value="branding">
					<Branding></Branding>
				</v-tab-item>
				<v-tab-item value="customer">
					<template v-if="settingTab == 'customer'">
						<Customer></Customer>
					</template>
				</v-tab-item>
				<v-tab-item value="supplier">
					<template v-if="settingTab == 'supplier'">
						<Supplier></Supplier>
					</template>
				</v-tab-item>
				<v-tab-item value="product">
					<template v-if="settingTab == 'product'">
						<Product></Product>
					</template>
				</v-tab-item>
				<v-tab-item value="service">
					<template v-if="settingTab == 'service'">
						<Service></Service>
					</template>
				</v-tab-item>
				<v-tab-item value="purchase-order">
					<template v-if="settingTab == 'purchase-order'">
						<PurchaseOrder></PurchaseOrder>
					</template>
				</v-tab-item>
				<v-tab-item value="quotation">
					<template v-if="settingTab == 'quotation'">
						<Quotation></Quotation>
					</template>
				</v-tab-item>
				<v-tab-item value="project">
					<template v-if="settingTab == 'project'">
						<Project></Project>
					</template>
				</v-tab-item>
				<v-tab-item value="expense">
					<template v-if="settingTab == 'expense'">
						<Expense></Expense>
					</template>
				</v-tab-item>
				<v-tab-item value="category">
					<Category></Category>
				</v-tab-item>
				<v-tab-item value="email-template">
					<EmailTemplate></EmailTemplate>
				</v-tab-item>
				<v-tab-item value="country-list">
					<template v-if="settingTab == 'country-list'">
						<CountryList></CountryList>
					</template>
				</v-tab-item>
				<v-tab-item value="terms-conditions">
					<template v-if="settingTab == 'terms-conditions'">
						<TermsAndConditionsAll></TermsAndConditionsAll>
					</template>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</v-layout>
</template>
<script>
import CompanySetting from "@/view/module/setting/company/CompanySetting";
import CountryList from "@/view/module/setting/pages/CountryList";
import EmailTemplate from "@/view/module/setting/email/EmailTemplate";
import RolePermission from "@/view/module/setting/rolePermission/RolePermission";
import Branding from "@/view/module/setting/branding/Branding";
import Customer from "@/view/module/setting/pages/Customer";
import Supplier from "@/view/module/setting/pages/Supplier";
import Product from "@/view/module/setting/pages/Product";
import Service from "@/view/module/setting/pages/Service";
import PurchaseOrder from "@/view/module/setting/pages/PurchaseOrder";
import Quotation from "@/view/module/setting/pages/Quotation";
import Project from "@/view/module/setting/pages/Project";
import TermsAndConditionsAll from "@/view/module/setting/pages/TermsAndConditionsAll";
import Expense from "@/view/module/setting/pages/ClaimExpense";
import Category from "@/view/module/setting/pages/Category";
import { SET_BARCODE_SETTING } from "@/core/services/store/config.module";
export default {
	name: "AllSettings",
	components: {
		CompanySetting,
		CountryList,
		EmailTemplate,
		RolePermission,
		Branding,
		Customer,
		Supplier,
		Product,
		Service,
		PurchaseOrder,
		Quotation,
		Project,
		Expense,
		TermsAndConditionsAll,
		Category,
	},
	created() {
		const _this = this;
		if (_this.$route.query && _this.$route.query.tab) {
			_this.settingTab = _this.$route.query.tab;
		}
	},
	computed: {
		settingTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (query.tab !== "category") {
					delete query.subCat;
				}
				if (query.tab === "category") {
					query.subCat = "designation";
				}
				if (val != this.settingTab) {
					this.$router.push({ query });
				}
			},
			get() {
				return this.$route.query.tab || "company_details";
			},
		},
	},
	mounted() {
		this.$store.dispatch(SET_BARCODE_SETTING);
	},
};
</script>
